import { Upgrade } from 'components/icons/upgrade'
import React from 'react'
import { UpgradeButton } from './button'
import {RASA_PRICING_URL} from '../../constants'
import './styles.scss'
import { Button } from 'reactstrap'

export interface UpgradeComponentProps extends React.HTMLAttributes<HTMLElement> {
  source: string,
  featuresTitle?: string,
  features: string[],
  headline?: string,
  description: string,
  buttonText?: string,
  onCancel?: any,
}

export class UpgradeComponent extends React.Component<UpgradeComponentProps> {
  constructor(props: UpgradeComponentProps) {
    super(props)
  }
  public render() {
      return (
        <div className={`upgrade-box ${this.props.onCancel ? 'customTemplate' : ''}`}>
          <div className="logo">
            <Upgrade svgwidth={30} svgheight={30}/>
          </div>
          <div className="title">
            {this.props.headline ? this.props.headline : 'Step Up to More'}
          </div>
          <div className="description">
            {this.props.description}
          </div>
          <div className="features">
            <div className="features-title">
              {this.props.featuresTitle ? this.props.featuresTitle : 'Here\'s what you get when you upgrade'}
            </div>
            {this.props.features.map((feature, index) => {
              return <div key={index} className="feature">
                <div className="feature-icon">
                  <i className="fas fa-check"></i>
                </div>
                <div className="feature-text">
                  <span dangerouslySetInnerHTML={{__html: feature}}></span>
                </div>
              </div>
            })}
          </div>
          <div className="pricing-link">
            To see an exact breakdown of what you'll receive with your upgrade,<br />
            visit our <a href={RASA_PRICING_URL} target="_blank">pricing page.</a>
          </div>
          <div className={`button ${this.props.onCancel ? 'upgrade-buttons' : ''}`}>
            <UpgradeButton source={this.props.source} text={this.props.buttonText ? this.props.buttonText : 'Upgrade your plan'} />
            {
              this.props.onCancel ?
                <Button className="btn-danger" onClick={() => this.props.onCancel()}>
                  Cancel
                </Button> : null
            }
          </div>
        </div>
      )
  }
}
