import * as GlobalConstants from '../../constants'

export const ADD_SOURCE_ADDITIONAL_FEATURES = [
  'Additional Sources',
  'Additional Newsletters',
  'Access to integrations',
]

export const SCHEDULE_CONTENT_ADDITIONAL_FEATURES = [
  'Ability to schedule content to send to all your subscribers on specific dates',
  'Ability to schedule promotions to send to all your subscribers on specific dates',
  'Ability to schedule Text/HTML content to send to all your subscribers on specific dates',
]

export const SECTIONS_ADDITIONAL_FEATURES = [
  'Ability to create sections within your newsletter for specific content',
  'Ability to add additional sources',
  'Ability to add more newsletters',
]

export const NEWSLETTER_ADDITIONAL_FEATURES = [
  'Additional Newsletters',
  'Additional Sources',
  'Access to more integrations',
]

export const INTEGRATIONS_ADDITIONAL_FEATURES = [
  'Access to integrations to automate your workflow',
  `To see what integrations come with which plan, visit <a href="${GlobalConstants.RASA_HELP_INTEGRATION_WORK}" target="_blank">this page</a>`,
]

export const DESIGN_CUSTOMTEMPLATE_ADDITIONAL_FEATURES = [
  `Ability to upload your own custom HTML template design (code must be compatible with ${GlobalConstants.BRAND_NAME})`,
  `Full control of your design beyond ${GlobalConstants.BRAND_NAME} out of the box templates`,
  'Access to other great features and additional resources',
]

export const DESIGN_LAYOUT_ADDITIONAL_FEATURES = [
  'Additional Layout Modules',
  'Access to custom templating',
  'Additional design support'
]

export enum UPGRADE_BUTTON_COLOR {
  BLACK = '#000000',
  WHITE = '#FFFFFF',
}
