import * as React from 'react'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const {TagCloud} = require('react-tagcloud')
import sample from 'lodash/sample'
import * as Constants from './constants'
import './styles.css'

export interface Word {
  [key: string]: string | number,
  value: string,
  count: number,
  key?: string,
  color?: string,
}

interface WordCloudProps {
  data: Word[],
  shuffle?: boolean,
}

interface ColorsObject {
  [key: string]: string
}

const setColors = (colors: string[], words: Word[]): ColorsObject => {
  return words.reduce((acc, cur) => {
    acc[cur.name] = sample(colors)
    return acc
  }, {} as ColorsObject)
}

export class WordCloud extends React.Component<WordCloudProps> {

  public render() {
    const {data} = this.props
    const colors = setColors(Constants.colors, data)
    return data.length === 0 ?
    <div className="tag-cloud">
      <h4 className="cloud-title">No Topic Available</h4>
    </div>
    :
    <TagCloud
    disableRandomColor
    minSize={22}
    maxSize={48}
    key={data.values.name}
    shuffle={this.props.shuffle || false}
    renderer={(tag: {name: string}, size: string) => {
      return <span key={tag.name}
                   style={{
                      color: colors[tag.name],
                      display: 'inline-block',
                      fontSize: `${size}px`,
                      marginRight: '0.5rem',
                    }}>{tag.name}
             </span>}}
    tags={data}
    />
  }
}
