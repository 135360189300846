import { SelectColor } from 'components/colors/component'
import { WysiwygEditor } from 'components/wysiwyg/component'
import { ToggleOn } from 'components/icons/toggleon'
import { ToggleOff } from 'components/icons/toggleoff'
import { isTruthy, stripHTML } from 'generic/utility'
import * as React from 'react'
import { Input } from 'reactstrap'
import { getConfiguration } from '../../utils'
import { UnsubscribeLayoutAttributes } from '../constants'
import { EditSectionProps } from '../types'

export class UnsubscribeSettings extends React.Component<EditSectionProps> {
  constructor(props: EditSectionProps) {
    super(props)
  }

  public render() {
    return <div className="logo-size">
      <span className="title">{this.props.name}</span>
      <div className="section">
        <div className="block">
        <div className="title">Unsubscribe Header</div>
          <div>
            <Input value={
              getConfiguration(this.props.data, UnsubscribeLayoutAttributes.HeaderText)}
              maxLength={100}
              onChange={(e) => {
                this.props.onChange(UnsubscribeLayoutAttributes.HeaderText, e.target.value)
              }}/>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="block">
        <div className="title">Unsubscribe Description</div>
        <div className="unsubscribe_header_text">
            <WysiwygEditor
              htmlEdited={false}
              html={this.getUnsubscribeHeaderDescription()}
              onChange={(html: string, isHtmlEdited: boolean) => {
                if (stripHTML(html) === '') {
                  this.props.onChange(UnsubscribeLayoutAttributes.HeaderDescription, '')
                } else {
                  this.props.onChange(UnsubscribeLayoutAttributes.HeaderDescription, html)
                }
              }}/>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="block">
          <div className="title">Unsubscribe Button</div>
          <div>
            <span>Text</span>
            <Input value={
              getConfiguration(this.props.data, UnsubscribeLayoutAttributes.UnsubscribeButtonText)}
              maxLength={100}
              onChange={(e) => {
                this.props.onChange(UnsubscribeLayoutAttributes.UnsubscribeButtonText, e.target.value)
              }}/>
          </div>
          <div>
            <span>Color</span>
            <SelectColor
              key={UnsubscribeLayoutAttributes.UnsubscribeButtonColor}
              className={'secondary'}
              selectColor={(c: string) => {
                this.props.onChange(UnsubscribeLayoutAttributes.UnsubscribeButtonColor, c)}}
              color={getConfiguration(this.props.data,
                UnsubscribeLayoutAttributes.UnsubscribeButtonColor, '#000000')} />
          </div>
          <div>
            <span>Multi-Newsletter Unsubscribe</span>
            <br />
            <button
                className="toggle-button"
                style={{ background: 'none', border: 'none', outline: 'none', cursor: 'pointer' }}
                onClick={() => this.props.onChange(UnsubscribeLayoutAttributes.MultiNewsletterUnsubscribe, !this.isMultiNewsletterUnsubscribeEnabled())}
              >
                {this.isMultiNewsletterUnsubscribeEnabled() ? <ToggleOn /> : <ToggleOff />}
            </button>
          </div>
        </div>
      </div>
    </div>
  }

  private isMultiNewsletterUnsubscribeEnabled = (): boolean => isTruthy(
    getConfiguration(this.props.data, UnsubscribeLayoutAttributes.MultiNewsletterUnsubscribe, false)
  )

  private getUnsubscribeHeaderDescription = (): any => {
    const unsubscribeHeaderrDescription = getConfiguration(this.props.data,
      UnsubscribeLayoutAttributes.HeaderDescription)
    return unsubscribeHeaderrDescription ? unsubscribeHeaderrDescription : ''
  }
}
