import { MarkettingToolsIcon } from 'components/icons/markettingtools' // need to change
import { OpenLinkIcon } from 'components/icons/openlink'
import { RasaContext } from 'context'
import React, {Component} from 'react'
import * as GenericRedux from '../../generic/genericRedux'
import { copyToClipboard } from '../../generic/utility'
import './styles.css'
import { BRAND_NAME } from '../../constants'

interface ChromeExtComponentProps {
  name: string,
  description: string,
  link: string,
}

const ChromeExtComponent = (props: ChromeExtComponentProps) =>
  <div className="chrome-link">
    <div className="left-part">
      <div className="first-line">
        <div className="name">{props.name}</div>
      </div>
      <div className="description">{props.description}</div>
    </div>
    <div className="middle-part">
      <a target="_blank" href={props.link} className="open-link"><OpenLinkIcon /></a>
    </div>
    <div className="right-part">
      <input type="text" value={props.link} readOnly={true}/>
      <button onClick={() => copyToClipboard(props.link) }>Copy</button>
    </div>
  </div>

interface WidgetsChromeState {
  communityId: string
  communityGuid: string
}

class WidgetsChromeComponent extends Component<any, WidgetsChromeState> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props)

    this.state = {
      communityId: '',
      communityGuid: '',
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        communityId: activeCommunity.communityId,
        communityGuid: activeCommunity.data.community_guid,
      })
    })
  }

  public render() {
    return (
      <div className="widgets-tools-chrome">
        <div className="contentpane-header-wrapper">
          <div className="contentpane-icon-wrapper">
            <MarkettingToolsIcon/>
          </div>
          <div className="header">
            <div className="contentpane-section-title-wrapper">
              <div className="section-header-text">Chrome extension</div>
            </div>
            <div className="text">
              Use our chrome extension to add articles to your content pool as you browse the web
            </div>
          </div>
        </div>
        <ChromeExtComponent
          name="Chrome extension"
          description={`Just follow this link to install! Currently only available to users with one ${BRAND_NAME} newsletter.`}
          link={this.chromeExtUrl()} />

     </div>
    )
  }

  private chromeExtUrl = () => {
    return 'https://chrome.google.com/webstore/detail/rasaio/jkooeebkmkghhhmohahoaifamgaecakj?hl=en'
  }
}

export const WidgetsChrome = GenericRedux.registerNewDatasetComponent(
  WidgetsChromeComponent, 'widgets_chrome')
