import { PARTNER_OPTIONS } from "store"

declare const RASA_PUBLIC_SERVICE_API: string

export const SESSION_STORAGE_KEY = 'RASA_SESSION'

export const getPublicServerUrl = (): string => {
  return `${RASA_PUBLIC_SERVICE_API}`
}

export const getPartnerOptions = (): Record<string, any> => {
  try {
    const config = window.localStorage.getItem(PARTNER_OPTIONS)
    return config ? JSON.parse(config) : {}
  } catch (error) {
    return {}
  }
}

export const getOptionValue = (key: string, defaultValue: string): string => {
  const partnerOptions = getPartnerOptions();

  if(!partnerOptions) {
    return defaultValue
  }

  return partnerOptions[key] || defaultValue
}

export const BRAND_NAME: string = getOptionValue('BRAND_NAME', 'rasa.io')
export const RASA_HOME_URL: string = getOptionValue('HOME_URL', 'http://www.rasa.io')
export const RASA_PRESS_URL: string =  getOptionValue('PRESS_URL', RASA_HOME_URL + '/news')
export const RASA_PRIVACY_URL: string = getOptionValue('PRIVACY_URL', RASA_HOME_URL + '/privacy')
export const RASA_PRICING_URL = getOptionValue('PRICING_URL', RASA_HOME_URL + '/pricing')
export const RASA_HELP_BASE_URL: string = getOptionValue('HELP_URL', 'https://help.rasa.io')
export const RASA_HELP_HUBSPOT_DOCUMENTATION = getOptionValue('HELP_HUBSPOT_DOCUMENTATION', null)
export const RASA_HELP_SALESFORCE_DOCUMENTATION = getOptionValue('HELP_SALESFORCE_DOCUMENTATION', null)
export const RASA_HELP_INTEGRATION_WORK = getOptionValue('HELP_INTEGRATION_WORK', null)
export const RASA_HELP_EMBED_TOP_ARTICLE = getOptionValue('HELP_EMBED_TOP_ARTICLE', null)
export const RASA_HELP_ANTI_SPAM_EMAIL_DELIVERABILITY = getOptionValue('HELP_ANTI_SPAM_EMAIL_DELIVERABILITY', null)
export const RASA_HELP_OVERVIEW_OF_DOMAIN_AUTH = getOptionValue('HELP_OVERVIEW_OF_DOMAIN_AUTH', null)
export const RASA_HELP_HOW_TO_AUTH_DOMAIN = getOptionValue('HELP_HOW_TO_AUTH_DOMAIN', null)
export const RASA_HELP_FIND_RSS_FEED = getOptionValue('HELP_FIND_RSS_FEED', null)
export const RASA_HELP_SUBSCRIBER_PERMISSION = getOptionValue('HELP_SUBSCRIBER_PERMISSION', null)
export const RASA_HELP_DMARC = getOptionValue('HELP_DMARC', null)
export const RASA_HELP_ADD_GOOGLE_NEWS = getOptionValue('HELP_ADD_GOOGLE_NEWS', null)
export const SUPPORT_EMAIL = getOptionValue('SUPPORT_EMAIL', 'support@rasa.io')
export const SUPPORT_MAIL_TO = `mailto:${SUPPORT_EMAIL}`
export const HELP_MAIL_TO =  getOptionValue('HELP_MAIL_TO', 'mailto:help@rasa.io')
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const EMAIL_FOOTER_IMAGE =  getOptionValue('EMAIL_FOOTER_IMAGE', null)
export const WHITELABEL_LOGO_URL =  getOptionValue('WHITELABEL_LOGO_URL', null)

export const STATUS_CODES = {
  UNPROCESSABLE: 422
}

export const IGNORE_SENTRY_ERROR = ['MissingToken']
