import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { Upgrade } from 'components/icons/upgrade'
import * as GA from 'google-analytics'
import {pipe, tap} from 'lodash/fp'
import * as Router from '../../router'
import { UPGRADE_BUTTON_COLOR } from './constants'

import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { noMoreUpgradeAvailable } from 'generic/utility'
import React, {Component} from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { EnterpriseRefComponent } from './enterprise-ref'

export const preventDefaultThen = (fn: any) => pipe(
  tap((e: React.SyntheticEvent) => e.preventDefault()),
  fn,
)

export const stopPropagationThen = (fn: any) => pipe(
  tap((e: React.SyntheticEvent) => e.stopPropagation()),
  fn,
)

export const preventDefault = () => (e: React.SyntheticEvent) => e.preventDefault()

interface ComponentProps {
  color?: UPGRADE_BUTTON_COLOR,
  showIcon?: boolean
  source: string,
  text: string,
}

interface UpgradeButtonOwnProps {
  callback?: any,
  style?: any,
}

interface UpgradeButtonState {
  loading: boolean,
  activeCommunity: any,
  isNoMoreUpgradeAvailable: boolean,
}

type UpgradeButtonProps = ComponentProps & UpgradeButtonOwnProps

export class UpgradeButton extends Component<UpgradeButtonProps, UpgradeButtonState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: UpgradeButtonProps) {
    super(props)
    this.state = {
      loading: true,
      activeCommunity: {},
      isNoMoreUpgradeAvailable: true,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.setState({
        activeCommunity,
        isNoMoreUpgradeAvailable: noMoreUpgradeAvailable(activeCommunity.billingInfo.currentPlan),
        loading: false,
      })
    })
  }

  public render() {
    return (
      this.state.loading ? <Loading size="64" /> :
      <div className="upgrade-button">
        {this.getRenderComponent()}
      </div>
    )
  }

  private getRenderComponent = () => {
    if (this.state.isNoMoreUpgradeAvailable) {
      return <EnterpriseRefComponent showInline={true} />
    }
    return <button className={`upgrade-button${this.props.color === UPGRADE_BUTTON_COLOR.WHITE ? '-white' : ''} primary btn btn-secondary`}
    onClick={preventDefaultThen(() => {
      if (this.props.source) {
        this.context.store.dispatch(GA.sendGa4Event(GA.createUpgradeEvent(this.props.source)))
        this.context.store.dispatch(Router.push(DashboardMenuOption.billing + '?upgrade_from=' + this.props.source))
      }
      if (this.props.callback) {
        this.props.callback()
      }},
    )}
    style={this.props.style}>
    {this.props.showIcon &&
      <div className="button-icon">
        <Upgrade svgwidth={30} svgheight={30}/>
      </div>}
    <div className="button-text">
      {this.props.text}
    </div>
  </button>
  }
}
