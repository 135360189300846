import { RasaContext } from 'context'
import * as React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { connect } from 'react-redux'
import * as Router from '../../../router'
import { Dataset } from '../../../generic/dataset'
import * as Utils from '../utils'
import { BRAND_NAME } from '../../../constants'
interface NewsletterStatsProps {
  width?: string,
  height?: string,
  title?: string,
}

interface NewsletterStatsState {
  communityId: string,
  isLoading: boolean,
  newsletterSent: number,
  totalDelivered: number,
  totalNumbersOfHours: number,
}
export class NewsletterStatsClass extends React.Component<NewsletterStatsProps, NewsletterStatsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props)
    this.state = {
      communityId: null,
      isLoading: true,
      newsletterSent: 0,
      totalDelivered: 0,
      totalNumbersOfHours: 0
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.setState({
        communityId: activeCommunity.communityId,
      })
      new Dataset().loadCommunityDataset('communityIssuesStats', activeCommunity.communityId, [{param: 'status', value: 'Complete'}]).
      then((result) => {
        if (result && result.length > 0 && result[0].length > 0) {
          const data = result[0][0]
          this.setState({
            newsletterSent: data.newsletter_sent,
            totalDelivered: data.total_delivered || 0,
            totalNumbersOfHours: data.newsletter_sent * 4,
            isLoading: false,
          })
        } else {
          this.setState({
            newsletterSent: 0,
            totalDelivered: 0,
            totalNumbersOfHours: 0,
            isLoading: false,
          })
        }
      })
    })
  }
  public render() {
    return this.state.isLoading ? null :
      <div>
        <div className="container">
          <div className="row boxes-header">
            <div className="col-md-4 mb-4 boxes">
              <div className="metric-header">Editions Sent</div>
              <div className="d-flex align-items-stretch">
                <div className="metric-box flex-fill">
                  <p>{Utils.abbreviateNumber(this.state.newsletterSent)}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 boxes">
              <div className="metric-header">Newsletters Delivered</div>
              <div className="d-flex align-items-stretch">
                <div className="metric-box flex-fill">
                  <p>{Utils.abbreviateNumber(this.state.totalDelivered)}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 boxes">
              <div className="metric-header"># of Hours Saved With {BRAND_NAME}</div>
              <div className="d-flex align-items-stretch">
                <div className="metric-box flex-fill">
                  <p>{Utils.abbreviateNumber(this.state.totalNumbersOfHours)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export const NewsletterStatsComponent = connect(
  null,
  {
    push: Router.push,
  },
)(NewsletterStatsClass)
