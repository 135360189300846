import { Upgrade } from 'components/icons/upgrade'
import React from 'react'
import './styles.scss'
import {RASA_PRICING_URL} from '../../constants'

export interface EnterpriseRefComponentProps {
  description?: string,
  showInline?: boolean,
  className?: string,
}

export class EnterpriseRefComponent extends React.Component<EnterpriseRefComponentProps> {
  constructor(props: EnterpriseRefComponentProps) {
    super(props)
  }
  public render() {
    return (
      <div className={`enterprise-ref ${this.props.showInline ? 'upgrade-box-inline' : 'upgrade-box'} `}>
        <div className="logo">
          <Upgrade svgwidth={30} svgheight={30}/>
        </div>
        <div className="description text-center">
          {
            this.props.description ? this.props.description : <a target="_blank" rel="noopener" href={RASA_PRICING_URL}>
              Learn more about Enterprise
            </a>
          }
        </div>
      </div>
    )
  }
}
